/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/lab/layout";
import { ListItemModel } from "../../models/ListItemModel";
import WorkList from "../../components/works/work-list";
import { Utils } from "../../utils";
import MediaQuery from "react-responsive";
import WorkList1 from "../../components/works/work-list-1";
import WorkList2 from "../../components/works/work-list-2";

type DataProps = {
  data: {
    allWpLab: { nodes: ListItemModel[] };
  };
};

const WorkPage = ({ data }: DataProps) => {
  // console.log(data);

  const items = data.allWpLab.nodes;

  return (
    <Layout title="PROJECT">
      <main
        css={css`
          padding-top: 88px;
          @media (min-width: 767px) {
            padding-top: 147px;
          }
          @media (min-width: 1299px) {
            padding-top: 159px;
          }
        `}
      >
        <div style={{ margin: "0 20px" }}>
          <MediaQuery maxWidth={767}>
            <WorkList1 color="white" path="/lab/work/" data={items} />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <WorkList2 color="white" path="/lab/work/" data={items} />
          </MediaQuery>
        </div>
      </main>
    </Layout>
  );
};
export default WorkPage;

export const pageQuery = graphql`
  query {
    allWpLab(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        title_en {
          titleEn
        }
        slug
        date
        thumbnail {
          thumbnailContent {
            type
            movie {
              guid
            }
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                    fluid {
                      originalImg
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
